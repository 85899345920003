<template>
    <div data-aos="fade-down" class="mt-1">
        <div class="img_container">
            <img :src="url" alt="Banner" class="img-fluid w-100">
            <div class="centered">

                <h2 v-if="description != ''">{{ $t(description) }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    url : {
      type : [String],
      default : []
    },
    description : {
      type : [String],
      default : []
    },
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>


<style  scoped>
    /* img{
        height: 300px;
    } */
    .img_container {
        position: relative;
        text-align: center;
        color: white;
    }
    .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
    }
    .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
    .centered h2 {
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        background: #e7f1fd;
        color: #04aabb;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
    }
</style>
