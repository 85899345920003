<template>
  <br /><br /><br />
  <br /><br /><br />
  <br /><br /><br />
  <div class="text-center">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLink",
  data() {},
  methods: {},
  async created() {},
  mounted() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = "https://apps.apple.com/us/app/etipol-express/id6547848063"; // App Store link
    } 
    // Android detection
    else if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.etipolexpress.client"; // Play Store link
    } 
    // Fallback URL for desktop or unsupported devices
    else {
        window.location.href = "https://etipolexpress.com";
    }
  },
};
</script>