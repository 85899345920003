import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomePage'
import AboutView from '@/views/AboutPage'
import ContactView from '@/views/ContactPage'
import ConditionView from '@/views/ConditionPage'
import PrivacyView from '@/views/PrivacyPage'
import Page from '@/views/Page'
import AppLink from '@/views/AppLink'
import SearchView from '@/views/SearchPage'
import MainLayout from '@/components/inc/MainLayout'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children : [
      {
        path: '',
        name: 'home',
        component: HomeView,
        props : true
      },
      {
        path: '/about',
        name: 'about',
        component: AboutView,
        props : true
      },
      {
        path: '/contact',
        name: 'contact',
        component: ContactView,
        props : true
      },
      {
        path : '/search',
        name : 'search',
        component :SearchView
      },
      {
        path: '/termsandcondition',
        name: 'termsandcondition',
        component: ConditionView,
        props : true
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyView,
        props : true
      },
      
      {
        path: '/page/:pageName',
        name: 'Page',
        component: Page,
        props : true
      },

      {
        path: '/app-link',
        name: 'app-link',
        component: AppLink,
        props : true
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect : '/'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path != '/'){
    if($('#dropNavBoard').hasClass("dropdown-active")){
      $('#dropNavBoard').toggleClass('dropdown-active');
    }
    if($('#dropNav').hasClass("dropdown-active")){
      $('#dropNav').toggleClass('dropdown-active');
    }
    // $('.dropdown').trigger('click');
    $('.bi-x').trigger('click');
  }
  next();
});

export default router
